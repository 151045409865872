@import './../../../scss/main';

.commentSection {
    margin: 2em 0 2em 0;
    padding: 1em 0;

    &-header {
        background-color: $color-lightgray;       
        padding: .6em;
        border-radius: $article-radius;

        h3 {
            margin-bottom: .6em;
        }

        div {
            display: flex;
        }

        &-img {
            background-color: rgb(189, 189, 189);        
            width: 2em;
            height: 2em;
            border-radius: 20px;
            margin-right: .6em;
            object-fit: cover;
        }
        &-form {
            display: flex;
            flex-grow: 1;
            align-items: center;
        }
        &-input {
            border: none;
            flex-grow: 1;
            border-radius: $article-radius;
            width: 6rem;
            font-size: .9rem;
            padding: 1em; 
            &:disabled {
                background-color: rgb(243, 243, 243);
                border: 1px solid rgba(146, 66, 66, 0.479);
            }
        }
        input[type=text]:focus {
            outline: none;
            border: 1px solid rgba(128, 128, 128, 0.479);
        }
    
        &-sendBtn {
            margin-left: .6em;
            border-radius: $article-radius;
            border: none;
            background-color: $color-primary;
            color: $color-light;
            font-weight: 600;
            font-size: .9rem;
            padding: 1rem 2rem; 
        }
    }
}
.commentSection-blank {
    background-color: #ffffff;
    margin-top: .2em;
    padding: 1em;
    font-family: $font-poppins;
    border-radius: $article-radius;  
    text-align: center; 
    position: relative;
    overflow: hidden;

    &-img {
        position: absolute;
        width: 200px;
        top: -3rem;
        left: -3rem;
        transform: rotate(.95turn); 
    }
    h2, h4 {
        position: relative;
        color: rgb(141, 141, 141);
        z-index: 10;
    }
    h2 {
        font-size: 1.2rem;
    }
}