@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap");
.autocompleteComponent {
  margin-top: 2rem;
}

.oneOfMany h3 {
  font-weight: 500;
}
.oneOfMany-selection {
  margin-top: 2rem;
  background-color: #e4e8ec;
  border-radius: 0.2em;
  padding: 1rem;
}
.oneOfMany-selectionName {
  color: #1a1a1a;
  font-size: 1.4rem;
  font-weight: 700;
}/*# sourceMappingURL=OneOfMany.css.map */