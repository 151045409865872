@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;500;600;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');

// ********************************
// *** Variables ******************
$color-primary: #1DA6FB;
$color-secondary: #6EE266;
$color-background: #242427; 
$color-surface: #37373b; 
$color-lightgray: #e4e8ec;
$color-lightergray: #f9fbff;
$color-light: #f8f8ff;

$article-radius: .2em;
$article-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

$font: 'Oswald', sans-serif;
$font-poppins: 'Poppins', sans-serif;
$font-roboto: 'Roboto', sans-serif;
$font-color: #1a1a1a;

$NFL-color: rgb(204, 66, 66);
$NBA-color: rgb(231, 215, 68);
$MLR-color: rgb(109, 204, 109);
$MLB-color: rgb(68, 66, 204);

