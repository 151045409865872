@import "./../../../scss/main";

.adWidget {
  background-color: $color-lightgray;
  padding: 1em;
  border-radius: $article-radius;

  top: 20px;

  p {
    font-family: $font;
    font-size: 1.2rem;
    padding-bottom: 0.4em;
  }

  &-body {
      background-color: $color-lightergray;
      border-radius: $article-radius;
      padding: 0.6em;
  }

  &-question {
    font-size: 0.9rem;
    font-weight: 500;
  }

  &-names {
    margin-top: .4rem;
    color:gray;
    font-size: small;
    font-weight: 800;
  }
}

