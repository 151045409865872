@import './../../../scss/main';

.article {
    width: 100%;
    height: 5em;

    margin-bottom: 10px;
    
    display: flex;
    text-decoration: none;

    box-shadow: $article-shadow;
}

.article-img {
    width: 6em;
    min-height: 1em; 
    border-radius: $article-radius;
}

.article-infoCont {
    padding: 10px;
    font-family: $font;

    &-title {
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.2rem;
        margin-bottom: .2em;
    }
    &-authorDate {
        font-size: .8rem;
    }
}

@media (min-width: 500px) {
    .article-img {
        width: 10em;
    }
    .article {
        height: 6em;
    }
  }