@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap");
.headerArticle {
  position: relative;
}

.headerArticle-img {
  width: 100%;
  height: 100%;
  border-radius: 0.2em;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.headerArticle-info {
  background-color: #f8f8ff;
  font-family: "Oswald", sans-serif;
  color: #1a1a1a;
  position: absolute;
  bottom: 16px;
  left: 10px;
  right: 10px;
  border-radius: 0.2em;
}
.headerArticle-info-tags {
  background-color: #242427;
  color: #f8f8ff;
  font-size: 0.8rem;
  padding: 3px 10px 3px 10px;
}
.headerArticle-info-title {
  padding-left: 10px;
  padding-top: 3px;
  font-weight: 600;
}
.headerArticle-info-summary {
  font-family: "Roboto", sans-serif;
  font-size: 0.9rem;
  padding: 0 10px 6px 10px;
}

@media (min-width: 800px) {
  .headerArticle {
    margin-bottom: 2rem;
  }
}/*# sourceMappingURL=HeaderArticle.css.map */