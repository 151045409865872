@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap");
.featuredArticlesCont h3 {
  font-family: "Oswald", sans-serif;
  font-size: 1.4rem;
  color: #1a1a1a;
  font-weight: 500;
  margin-bottom: 5px;
  margin-top: 10px;
}
.featuredArticlesCont-cont {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  -moz-column-gap: 0.6rem;
       column-gap: 0.6rem;
}/*# sourceMappingURL=FeaturedArticlesCont.css.map */