@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap");
.pickEmGameCard {
  background-color: #e4e8ec;
  padding: 1em;
  border-radius: 0.2em;
  margin-bottom: 0px;
  width: 100%;
}
.pickEmGameCard h1 {
  font-size: larger;
}/*# sourceMappingURL=PickEmGameCard.css.map */