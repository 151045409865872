@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap");
.nav {
  background-color: #242427;
  padding: 10px 20px;
  width: auto;
  height: 60px;
  z-index: 11;
  position: relative;
  justify-content: space-between;
  display: flex;
}

.nav__logo-cont {
  margin-right: 2em;
  cursor: pointer;
}

.nav__logo {
  height: 40px;
}

.nav-links {
  display: flex;
  align-items: center;
}
.nav-links-link {
  margin-right: 1em;
  padding: 0 0.6em 0 0.6em;
  cursor: pointer;
}
.nav-links-text {
  font-family: "Oswald", sans-serif;
  color: #e4e8ec;
  letter-spacing: 1px;
  padding: 0.4em;
}

.nav-linksToHide {
  display: none;
}

.nav-icons {
  display: none;
  align-items: center;
}

.menu {
  background-color: #27282a;
  color: #f8f8ff;
  font-weight: 500;
  padding: 0.5rem;
  position: absolute;
  top: 70px;
  right: 10px;
  border-radius: 3px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.menu ul {
  list-style-type: none;
}
.menu ul li {
  padding: 0rem 0rem;
  line-height: 1rem;
}
.menu-link {
  display: block;
  color: #f8f8ff;
  border-radius: 0.2em;
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  padding: 1rem 2rem;
}
.menu-link:hover {
  background-color: #37373b;
}
.menu-profileIMG {
  -o-object-fit: cover;
     object-fit: cover;
  width: 1.4em !important;
  height: 1.4em !important;
  border-radius: 20em;
  margin-right: 0.8em;
}
.menu-authBtn {
  cursor: pointer;
  border-radius: 0.2em;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  line-height: 1rem;
}
.menu-authBtn-icon {
  margin-right: 0.4em;
  width: 0.8em !important;
  height: 0.8em !important;
}

.logoutBtn {
  background-color: hsla(0, 100%, 40%, 0.769);
}
.logoutBtn:hover {
  background-color: hsl(0, 100%, 40%);
}

.loginBtn {
  background-color: hsla(135, 100%, 40%, 0.769);
  color: white;
}
.loginBtn:hover {
  background-color: hsl(135, 100%, 40%);
}

.menuIcon-cont {
  display: flex;
  align-items: center;
}

.menuIcon {
  color: #f8f8ff;
  width: 1.6em !important;
  height: 1.6em !important;
  cursor: pointer;
}

hr.solid {
  opacity: 0.3;
  margin: 0.4em 0 0.4em 0;
}

.sidebar {
  background-color: #37373b;
  width: 12em;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  right: -100%;
  transition: 850ms;
  border-radius: 0.2em 0 0 0.2em;
  overflow: hidden;
  z-index: 3;
}
.sidebar-close {
  width: auto;
  padding: 1em;
  display: flex;
  align-items: center;
}
.sidebar-close-icon {
  color: #f8f8ff;
  width: 1.2em !important;
  height: 1.2em !important;
}
.sidebar-profile {
  display: flex;
  align-items: center;
}
.sidebar-profile-img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 1.8em !important;
  height: 1.8em !important;
  border-radius: 20em;
  margin-right: 1.2em;
}
.sidebar-profile-name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.sidebar-items {
  width: 100%;
}
.sidebar-toggle {
  background-color: #242427;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: end;
  align-items: center;
  margin-bottom: 0.3em;
}
.sidebar-icon {
  width: 0.8em !important;
  height: 0.8em !important;
}
.sidebar-text {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 0.3em 0 0.3em 1em;
  list-style: none;
  height: 3em;
}
.sidebar-text a {
  text-decoration: none;
  color: #fcfcfc;
  font-size: 1rem;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 0.6em;
  border-radius: 0.2em;
}
.sidebar-text a:hover {
  background-color: lightslategray;
}
.sidebar-auth {
  color: #fcfcfc;
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 0.6em 0 0.6em 1em;
  border-radius: 0.2em;
  margin: 0.6em;
}
.sidebar-auth-icon {
  width: 0.8em !important;
  height: 0.8em !important;
}
.sidebar-login {
  background-color: rgba(0, 204, 78, 0.769);
}
.sidebar-logout {
  background-color: hsla(0, 100%, 40%, 0.769);
}

.landingLink {
  text-decoration: none;
}

.sidebar.active {
  right: 0;
  transition: 350ms;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

span {
  margin-left: 16px;
}

@media (min-width: 800px) {
  .nav {
    padding: 10px 40px;
  }
  .nav-linksToHide {
    display: flex;
  }
  .menuIcon-cont {
    display: none;
  }
  .nav-icons {
    display: flex;
  }
}/*# sourceMappingURL=Nav.css.map */