@import "./../../../scss/main";

.pickEmGameCard {
    background-color: $color-lightgray;
    padding: 1em;
    border-radius: $article-radius;
    margin-bottom: 0px;
    width: 100%;

    h1 {
        font-size: larger;
    }
}