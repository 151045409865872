@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap");
.matchSelections h2 {
  text-align: center;
}
.matchSelections-pagination {
  margin: 1rem;
  display: flex;
}
.matchSelections-pagination ul {
  margin: 0 auto;
}

.matchElement {
  background-color: #f9fbff;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 0.2em;
}

.matchInfo {
  margin: 1rem 0 0 0;
  align-content: center;
}

.btnContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  margin-top: 10px;
}

.btn {
  background-color: #e4e8ec;
  border: none;
  padding: 1rem;
  flex-grow: 1;
  border-radius: 0.2em;
  font-weight: bold;
  flex-basis: 50%;
  cursor: pointer;
}
.btn-teamName {
  font-size: 1rem;
  font-weight: 700;
}
.btn-teamNickname {
  font-size: 0.8rem;
}

button:disabled {
  cursor: default;
}

.active {
  background-color: #1DA6FB;
  color: #242424;
}

.won {
  background-color: rgb(38, 122, 38);
  color: white;
}

.lost {
  background-color: rgb(207, 62, 62);
  color: white;
}

.atSymbol-cont {
  display: flex;
}

.atSymbol {
  align-items: center;
  width: 1.5rem;
  filter: invert(0.3);
  vertical-align: center;
}/*# sourceMappingURL=MatchSelections.css.map */