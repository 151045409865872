@import "./../../../scss/main";

.recentArticles {
  margin-top: 10px;

  h3 {
    font-family: $font;
    font-size: 1.4rem;
    font-weight: 500;
    margin-bottom: 5px;
    margin-top: 10px;
  }

  &-linksCont {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    column-gap: 10px;
  }

  &-link {
    display: flex;
    color: $font-color;
    flex-grow: 1;
    flex-basis: 40%;
    margin-bottom: 10px;
  }
}

@media (min-width: 800px) {
  .recentArticles {
    &-linksCont {
      column-gap: 20px;
    }
    &-link {
      margin-bottom: 20px;
    }
  }
}