@import './../../../scss/main';

.pickEmGameHome {
  display: flex;
  justify-content: center;
  padding: 0 20px;
  width: auto;
  max-width: 1200px;
  margin: 0 auto 1em auto;

  h1 {
    margin-bottom: .8rem;
  }

  &-cont {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  &-left {
    order: 3;
    flex-grow: 1;
    margin: 20px 0 0 0;
    min-width: 20%;
  }
  &-middle {
    order: 1;
    flex-grow: 1;
    margin-top: 10px;
  }
  &-right {
    order: 2;
    flex-grow: 1;
    margin: 20px 0 0 0;
    min-width: 20%;
  }

}
.disabled-link {
  pointer-events: none;
}
.alert {
  width: 50%;
}

@media (min-width: 800px) {
    .pickEmGameHome-cont {
        flex-direction: row;
    }
    .pickEmGameHome-left {
        order: 1;
        margin-right: 20px;
    }
    .pickEmGameHome-middle {
        order: 2;
        flex-grow: 2;
        margin-top: 20px;
        width: 100%;
    }
    .pickEmGameHome-right {
        order: 3;
        margin-left: 20px;
    }
  }