* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.app {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}