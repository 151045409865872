@import "./../../../scss/main";

.headerArticle {
  position: relative;
}

.headerArticle-img {
  width: 100%;
  height: 100%;

  border-radius: $article-radius;
  box-shadow: $article-shadow;
}

.headerArticle-info {
  background-color: $color-light;
  font-family: $font;
  color: $font-color;

  position: absolute;
  bottom: 16px;
  left: 10px;
  right: 10px;

  border-radius: $article-radius;

  &-tags {
    background-color: $color-background;
    color: $color-light;
    font-size: 0.8rem;
    padding: 3px 10px 3px 10px;
  }
  &-title {
    padding-left: 10px;
    padding-top: 3px;
    font-weight: 600;
  }
  &-summary {
    font-family: $font-roboto;
    font-size: 0.9rem;
    padding: 0 10px 6px 10px;
  }
}

@media (min-width: 800px) {
  .headerArticle {
    margin-bottom: 2rem;
  }
}
