@import './../../../scss/main';

.featuredArticlesCont {
    h3 {
        font-family: $font;
        font-size: 1.4rem;  
        color: $font-color;
        font-weight: 500; 
        margin-bottom: 5px;
        margin-top: 10px;
    }
    &-cont {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr;
        column-gap: .6rem;
    }
}