@import './../../../scss/main';

.profilePage {
    padding: 1em;
    max-width: 960px; // 1280
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    align-content: stretch;

    &-stack {
        min-width: 400px;

        &-empty {
            padding: .6em 1em;
        }
    } 
}
.profilePage-header {
    background-color: white;
    max-width: 250px; 
    border-radius: $article-radius;
    display: flex;
    flex-direction: column;
    padding: 1em;
    font-family: $font;
    min-height: auto;

    &-info {
        display: flex;
        flex-direction: row;
    }
    &-infoText {
        padding-left: 1rem;
        padding-top: 1rem;
    }
    &-img {
        width: 4em;
        height: 4em;
        border-radius: 50px;
        align-self: center;
        margin-bottom: .4em;
        object-fit: cover;        
    }
    &-name {
        font-size: 1.4rem;
        font-weight: 500;
    }
    &-email {
        font-size: .9rem;
        opacity: .9;
    }
    &-statsCont {
        margin-top: 2rem;

        h3 {
            color: rgb(126, 126, 126);
            font-size: 1rem;
            margin-bottom: 0.4rem;
            font-weight: 500;           
        }
    }
    &-stats {
        display: flex;
        justify-content: flex-start;

        div {
            display: flex;
            flex-direction: column;
            margin-right: 1.6rem;
            
            label {
                color: rgb(126, 126, 126);
                font-size: .9rem;
            }
            span {
                font-size: 1.4rem;
                margin: 0;
            }
        }
    }
    &-bio {
        margin-top: 1rem;
    }
    &-editProfileBtn {
        background-color: $color-primary;
        border-radius: $article-radius;
        border: none;
        width: 100%;
        color: $color-light;
        font-weight: 600;
        font-size: .9rem;
        padding: .6em 1.4em; 
        margin-top: 2rem;
        cursor: pointer;
    }
}
.profilePage-comments {
    background-color: rgb(233, 233, 233);
    border-radius: $article-radius;
    overflow: hidden;
    margin-top: 1em;

    &-panel {
        background-color: $color-surface;
        padding: .4em 1em;
        color: $color-light;
        font-weight: 500;
    }
}
.profileComment {
    background-color: $color-lightgray;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: .6em 1em;
    
    
    &-commentInfo {
        display: flex;
        flex-direction: row;
        font-family: $font-roboto;
        color: gray;
        font-size: .9em;
        margin-top: .6em;
    }
    &-likeCount {
        margin-right: 2em;

        span {
            margin: 0;
            font-weight: 500;
            color: $color-surface;
        }
    }
    &-message {
        flex-grow: 1;
    }
}
.profileComment:nth-child(even) {
    background-color: white;
}

.form-container {
    input, textarea {
        display: block;
        width: 100%;
        padding: .5rem;
        margin: 0.2rem 0;
        font-size: 20px;
        border-radius: $article-radius;
    }
    textarea {
        height: 10rem;
    }

    &-btnContainer {
        display: flex;
        gap: 1rem;
        // float: right;

        button {
            border-radius: $article-radius;
            font-weight: 600;
            font-size: 1rem;
            padding: 1em 2em; 
            margin-top: 1rem;
            cursor: pointer;
        }
        &-cancelBtn {
            //color: $color-primary;
            border-color: $color-primary;
            border: 5px;
        }
        &-submitBtn {
            background-color: $color-primary;
            color: $color-light;
            border: none;
        }
    }
}


@media (min-width: 600px) {
    .profilePage {
        flex-direction: row;
    }
    .profilePage-header {
        margin-right: 1rem;;
    }
    .profilePage-comments {
        margin-top: 0;
    }
  }