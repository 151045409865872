@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap");
.articleItem {
  background-color: white;
  width: 100%;
  min-height: 16rem;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.articleItem-img {
  width: 100%;
  min-height: 7rem;
  height: 7rem;
  border-radius: 0.2em 0.2em 0 0;
  border-bottom: 4px solid #1DA6FB;
}

.articleItem-infoCont {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  width: 100%;
  padding: 0.6em;
  font-family: "Oswald", sans-serif;
}
.articleItem-infoCont-title {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.2rem;
  margin-bottom: 0.2em;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.articleItem-infoCont-summary {
  color: gray;
  font-weight: 400;
  font-size: 0.8rem;
  margin: 0.6em 0;
  font-family: "Roboto", sans-serif;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.articleItem-infoCont-authorDate {
  font-size: 0.8rem;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@media (min-width: 440px) {
  .articleItem-img {
    height: 10rem;
  }
  .articleItem-infoCont {
    padding: 1.2em;
  }
}
@media (min-width: 660px) {
  .articleItem-img {
    height: 12rem;
  }
}/*# sourceMappingURL=ArticleItem.css.map */