@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap");
.footer {
  background-color: #e4e8ec;
  display: flex;
  flex-direction: column;
  padding: 3rem 3rem 1rem 3rem;
  font-family: "Roboto", sans-serif;
  margin-top: auto;
}
.footer-cont {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto 0 auto;
  justify-content: space-between;
}
.footer-companyCol {
  margin-bottom: 2rem;
}
.footer-companyCol p {
  color: rgb(95, 95, 95);
  margin-top: 0.6rem;
  max-width: 300px;
}
.footer-linksColCont {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 2rem;
}
.footer-linksCol {
  margin-right: 3rem;
}
.footer-linksCol ul li {
  color: rgb(95, 95, 95);
  list-style: none;
  margin-bottom: 0.4rem;
}
.footer-linksCol:last-child {
  margin: 0;
}
.footer-newsletterCol {
  max-width: 500px;
}
.footer-legal {
  color: rgb(95, 95, 95);
  margin-top: 2em;
  padding-top: 1rem;
  text-align: center;
  border-top: 1px solid lightgray;
}
.footer-logo-cont {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #37373b;
  font-weight: 500;
  font-family: "Oswald", sans-serif;
  font-size: 1.6rem;
}
.footer-logo {
  height: 2rem;
  width: 2rem;
  margin-right: 0.6rem;
}
.footer-label {
  color: #37373b;
  font-size: 1.2rem;
  font-weight: 500;
  padding: 0.6em 0;
  width: auto;
}
.footer-newsletterCont {
  display: flex;
}
.footer-input {
  border: none;
  flex-grow: 1;
  border-radius: 0.2em;
  width: 6rem;
  font-size: 0.9rem;
  padding: 1em;
}
.footer input[type=email]:focus {
  outline: none;
  border: 1px solid rgba(128, 128, 128, 0.479);
}
.footer-subscribeBtn {
  margin-left: 0.3em;
  border-radius: 0.2em;
  border: none;
  background-color: #1DA6FB;
  color: #f8f8ff;
  font-weight: 600;
  font-size: 0.9rem;
  padding: 1em 2em;
}

@media (min-width: 500px) {
  .footer-linksColCont {
    flex-direction: row;
  }
}
@media (min-width: 1000px) {
  .footer-cont {
    flex-direction: row;
  }
  .footer-companyCol {
    margin-bottom: 0;
  }
  .footer-linksColCont {
    margin: 0 1rem 0 1rem;
  }
  .footer-newsletterCol {
    min-width: 250px;
    max-width: 800px;
  }
}/*# sourceMappingURL=Footer.css.map */