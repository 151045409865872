@import './../../../scss/main';

.autocompleteComponent {
    margin-top: 2rem;
}

.oneOfMany {
    h3 {
        font-weight: 500;
    }
    &-selection {
        margin-top: 2rem;
        background-color: $color-lightgray;
        border-radius: $article-radius;
        padding: 1rem;
    }
    &-selectionName {
        color: $font-color;
        font-size: 1.4rem;
        font-weight: 700;
    }
}