@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap");
.landing {
  display: flex;
  justify-content: center;
  padding: 20px;
  width: auto;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}
.landing-cont {
  display: flex;
  flex-direction: column;
  width: 100%;
}

@media (min-width: 800px) {
  .landing-cont {
    flex-direction: row;
  }
}/*# sourceMappingURL=Landing.css.map */