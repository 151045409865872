@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap");
.recentArticles {
  margin-top: 10px;
}
.recentArticles h3 {
  font-family: "Oswald", sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  margin-bottom: 5px;
  margin-top: 10px;
}
.recentArticles-linksCont {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  -moz-column-gap: 10px;
       column-gap: 10px;
}
.recentArticles-link {
  display: flex;
  color: #1a1a1a;
  flex-grow: 1;
  flex-basis: 40%;
  margin-bottom: 10px;
}

@media (min-width: 800px) {
  .recentArticles-linksCont {
    -moz-column-gap: 20px;
         column-gap: 20px;
  }
  .recentArticles-link {
    margin-bottom: 20px;
  }
}/*# sourceMappingURL=RecentArticles.css.map */