@import "./../../../scss/main";

.landing {
  display: flex;
  justify-content: center;
  padding: 20px;
  width: auto;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  &-cont {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

@media (min-width: 800px) {
  .landing-cont {
    flex-direction: row;
  }
}
