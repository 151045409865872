@import './../../../scss/main';

.pickEmGame {
  //background-color: lightcoral;
  margin: 0 auto 1em auto;
  width: auto;
  padding: 0 20px;
  max-width: 1200px;
  
  h1 {
    text-align: center;
    margin-bottom: .8rem;
  }

  &-cont {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  &-left {
    order: 3;
    flex-grow: 1;
    margin: 20px 0 0 0;
    min-width: 20%;
  }
  &-middle {
    order: 1;
    flex-grow: 1;
    margin-top: 10px;
  }
  &-right {
    order: 2;
    flex-grow: 1;
    margin: 20px 0 0 0;
    min-width: 20%;
  }
 
}

#btnContainer {
  //background-color: green;
  //width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
}

.btn {
    background-color: $color-lightgray;
    border: none;
    padding: 1rem;
    flex-grow: 1;
    border-radius: $article-radius;
    font-weight: bold;
}

.active {
    background-color: $color-primary;
    color: white;
  }

  @media (min-width: 800px) {
    .pickEmGame-cont {
        flex-direction: row;
    }
    .pickEmGame-left {
        order: 1;
        margin-right: 20px;
    }
    .pickEmGame-middle {
        order: 2;
        flex-grow: 2;
        margin-top: 20px;
        width: 100%;
    }
    .pickEmGame-right {
        order: 3;
        margin-left: 20px;
    }
  }