@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap");
.pollWidget {
  background-color: #e4e8ec;
  padding: 1em;
  border-radius: 0.2em;
  top: 20px;
}
.pollWidget h5 {
  margin-bottom: 0.6em;
  font-family: "Roboto", sans-serif;
}
.pollWidget form > div > div > label {
  margin-left: 0.6em;
  font-size: 0.9rem;
}
.pollWidget form > div > div > input, .pollWidget form > div > div > label {
  cursor: pointer;
}
.pollWidget form > div > div {
  margin-bottom: 0.2em;
}
.pollWidget p {
  font-family: "Oswald", sans-serif;
  font-size: 1.2rem;
  padding-bottom: 0.4em;
}
.pollWidget-body {
  background-color: #f9fbff;
  border-radius: 0.2em;
  padding: 0.6em;
  font-family: "Roboto", sans-serif;
}
.pollWidget-question {
  font-size: 0.9rem;
  font-weight: 500;
}
.pollWidget-submitBtn {
  background-color: #1DA6FB;
  border-radius: 0.2em;
  border: none;
  width: 100%;
  color: #f8f8ff;
  font-weight: 600;
  font-size: 0.9rem;
  padding: 0.6em 1.4em;
  margin-top: 0.6rem;
  cursor: pointer;
}/*# sourceMappingURL=PollWidget.css.map */